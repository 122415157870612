<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                   <ion-button @click="$router.go(-1)">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base text-gray-400 text-center font-semibold">Help &amp; Support</h1></ion-title>
                <ion-buttons slot="end">
                    <ion-button router-link="/home/settings">
                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-card class="mx-7.5 shadow-none">
                <h2 class="text-md text-gray-900 font-bold mb-2">Contact us</h2>
                <p class="text-md text-gray-900">If you have any questions or queries please do not hesitate to get in touch</p>
                <a class="text-center bg-white border-2 border-green-500 py-4 w-full text-green-500 text-md font-bold block rounded mt-4 mb-4 align-middle" href="https://www.savemoneycutcarbon.com/help-advice/contact/">Contact us <ion-icon class="-mb-2 w-6 h-6 text-smcc-smcc-green" :src="require('@/assets/Icons/external-link.svg')"></ion-icon></a>

            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonButtons, modalController, IonIcon, IonImg } from '@ionic/vue';
import {useRouter} from 'vue-router';
import TutorialModal from '@/components/TutorialModal.vue';
export default {
    name: 'ExploreContainer',
    props: {
        name: String
    },
    components: { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonButtons, IonIcon, IonImg },
    data() {
        return {
            user: []
        }
    },
    setup() {
        const openModal = async (id, type) => {
        const modal = await modalController.create({
            component: TutorialModal, //Modal is name of the component to render inside ionic modal
            componentProps: {
                id: id,
                type: type
            },
            swipeToClose: true,
            backdropDismiss: true,
        });
            return modal.present();
        };
        const router = useRouter();
        return {
            router,
            openModal
        };
    },

}
</script>

<style scoped>
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

.button-inner{
    justify-content: space-between;
}
/*! purgecss start ignore */

/*! purgecss end ignore */
</style>
